import React, { Component } from 'react';
import ReactTypingEffect from 'react-typing-effect';
import {Parallax} from "react-parallax";

class Home extends Component {
    render() {
        return (
   <div id="Home">

   		<header id="header-area" class="intro-section" >
   		            <Parallax
                                       blur={{min:0, max:0}}
                                       bgImage={require('../img/bg-4.jpg')}
                                       strength={700}
                                   >
                                   <div style={{ height: '800px' }} />
   			<div class="container">
   				<div class="row">
   					<div class="col-sm-12 text-center">
   						<div class="header-content">
   							<h1>Fresh Air Movement</h1>
   							<h4>Digital Workspace for Entrepreneurial Endeavors</h4>
   						</div>
   					</div>
   				</div>
   			</div>
   			   				</Parallax>


   		</header>

   	</div>
        )
    }
}

export default Home