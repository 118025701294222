import React, { Component } from 'react';

class Games extends Component {
    render() {
        return (
            <div >
                <h1>Games</h1>
                <h3>a</h3>

            </div>
        )
    }
}

export default Games