
import React, { Component } from 'react';
import Section from "./Section";


class Testimonials extends Component {
    render() {
        return (
                <section id="testimornial-area">
                    <div class="container">
                        <div class="row text-center">
                            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6 col-xxs-12">
                                <div class="testimonial-content">
                                    <img src={require("./../img/4-1.jpg")} alt="Image"/>
                                    <h2>Column One</h2>
                                    <p>Ut ac odio scelerisque ante ornare commodo. Sed faucibus dui libero, in tincidunt purus pretium quis. Fusce posuere egestas enim eu viverra.</p>

                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6 col-xxs-12">
                                <div class="testimonial-content">
                                    <img src={require("./../img/4-2.jpg")} alt="Image"/>
                                    <h2>Column Two</h2>
                                    <p>Ut ac odio scelerisque ante ornare commodo. Sed faucibus dui libero, in tincidunt purus pretium quis.</p>
                                    <a href="#" class="content-link">read it</a>

                                    <p id="redd"></p>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6 col-xxs-12">
                                <div class="testimonial-content">
                                    <img src={require("./../img/4-3.jpg")} alt="Image"/>
                                    <h2>Column Three</h2>
                                    <p>Ut ac odio scelerisque ante ornare commodo. Sed faucibus dui libero, in tincidunt purus pretium quis. Fusce posuere egestas enim eu viverra.</p>

                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6 col-xxs-12">
                                <div class="testimonial-content">
                                    <img src={require("./../img/4-4.jpg")} alt="Image"/>
                                    <h2>Column Four</h2>
                                    <p>Ut ac odio scelerisque ante ornare commodo. Sed faucibus dui libero, in tincidunt purus pretium quis.</p>
                                    <a href="#" class="content-link">details</a>

                                    <p id="dets"></p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="tm-box">
                                    <img src={require("./../img/4-5.jpg")} alt="Image" class="img-responsive"/>
                                    <div class="tm-box-description">
                                        <h2>One Big Column</h2>
                                        <p class="tm-box-p">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tempor eros eget eros maximus, ut cursus sem euismod. Donec iaculis tristique odio at consectetur. Nullam dignissim varius suscipit. Sed in leo sit amet velit finibus pretium. Vivamus dictum nisi ac fermentum interdum. Vestibulum vel mauris at erat mattis accumsan et ac lorem. Cras non venenatis orci, sed tincidunt massa. Duis nisl lectus, auctor eu sodales at, dignissim eu orci. Sed vitae venenatis magna, in blandit metus. Praesent volutpat cursus rhoncus. Aenean arcu diam, suscipit ac neque in, sollicitudin convallis orci.</p>
                                        <p class="tm-box-p">Fusce eu porta massa, sed tincidunt turpis. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus a urna tellus. Integer pharetra vitae nisi et lacinia. Morbi sagittis justo a velit placerat ullamcorper quis quis velit. Sed convallis at risus ullamcorper auctor. Praesent quis velit neque. Quisque semper porta nisi vitae suscipit. Duis lectus magna, ornare ac scelerisque quis, maximus eget nisi.</p>
                                        <a href="#" class="content-link">Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        )
    }
}

export default Testimonials