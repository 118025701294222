import React, {Component} from 'react';
import {Button } from "react-bootstrap";

class Navbar extends Component {
    render() {
        return (
           	<nav class="navbar navbar-fixed-top">
           		<div class="container">
           			<div class="navbar-header">
           				<button type="button" class="navbar-toggle">
           					<span class="icon-bar"></span>
           					<span class="icon-bar"></span>
           					<span class="icon-bar"></span>
           				</button>
           			</div>
           			<div class="single-page-nav sticky-wrapper" id="tmNavbar">
           				<ul class="nav navbar-nav">
           					<li><a href="#">Homepage</a></li>
           					<li><a href="#About">About Us</a></li>
           					<li><a href="#Services">Services</a></li>
           					<li><a href="#Contact">Contact</a></li>
           				</ul>
                    {/*<ul className="nav navbar-nav" style={{float:"right", color:"transparent"}}>*/}
                    {/*                    <li><a href="/Games">Games</a></li>*/}
                    {/*                    </ul>*/}
           			</div>
           		</div>

           	</nav>
        )
    }
}

export default Navbar