import React, { Component } from 'react'
import {Parallax} from 'react-parallax'

class Services extends Component {

    render() {
        return (
        <div id="Services">
				<section id="services-area" class="services-section">
					   		<Parallax
                                           blur={{min:-4, max:8}}
                                           bgImage={require('../img/bg-2.jpg')}
                                           bgImageAlt="the cat"
                                           strength={800}
                                       >
                                       <div style={{ height: '100px' }} />
					<div class="container">
						<div class="row">
							<div class="col-sm-12 text-center inner our-service">
								<div class="service">
									<h1>Our Services</h1>
									<p>Nunc diam leo, fringilla vulputate elit lobortis, consectetur vestibulum quam. Sed id
										felis ligula. In euismod libero at magna dapibus, in rutrum velit lacinia.
										Etiam a mi quis arcu varius condimentum.</p>
									</div>
								</div>
							</div>
						</div>
						</Parallax>
					</section>
					</div>
        )
    }
}

export default Services