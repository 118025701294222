
import React from 'react';
import Component from 'react';

import {
    BrowserRouter as Router,
    Route,
} from "react-router-dom";
import Navbar from './components/Navbar';
import Navbar2 from './components/Navbar2';
import Home from './components/Home';
import About from './components/About';
import Testimonials from './components/Testimonials';
import Services from './components/Services';
import Contact from './components/Contact';
import Blog from './components/Blog';
import Footer from './components/Footer';
import Games from './components/Games';

import template from "./index.html";
import './css/bootstrap.min.css';
import './css/style.css';


const App = ()  =>{
    return (

<div>
    <Router>
        <Route path="/" exact >
            <Navbar/>
            <Home/>
            <About/>
            <Blog/>
            <Services/>
            <Testimonials/>
            <Contact/>
            <Footer/>
        </Route>
        <Route path="/Games" exact>
            <Navbar2/>
            <Games/>
        </Route>
    </Router>
</div>
    );
}

export default App;