
import React, { Component } from 'react';

class About extends Component {
    render() {
        let intro ="Hello there, my name is Ryan Evans.";
        let aboutMe = "I am currently a contract IT Analyst working at John Deere.\n" +
            "I enjoy problem solving, contemplating societal norms and introspecting - all of which I use to fuel my mindset of constant betterment";

        return (
        <div id="About">
           		<section id="feature-area" class="about-section" >
           			<div class="container">
           				<div class="row text-center inner">
           					<div class="col-sm-4">
           						<div class="feature-content">
           							<img src={require("./../img/1-1.jpg")} alt="Image"/>
           							<h2 class="feature-content-title green-text">Bootstrap v3.3.6</h2>
           							<p class="feature-content-description">Morbi sagittis justo a velit placerat ullamcorper quis quis velit. Sed convallis at risus ullamcorper auctor. Praesent quis velit neque. Quisque semper porta nisi vitae suscipit. Duis lectus magna, ornare ac scelerisque.
           							</p>
           							<a href="#" class="feature-content-link green-btn">button green</a>
           						</div>
           					</div>
           					<div class="col-sm-4">
           						<div class="feature-content">
           							<img src={require("./../img/1-2.jpg")} alt="Image"/>
           							<h2 class="feature-content-title blue-text">Responsive Design</h2>
           							<p class="feature-content-description">Conquer Template is provided by templatemo for free of charge. You can use this template for any kind of website. No credit link is required. All images by <a href="http://unsplash.com" target="_parent">Unsplash</a>. Thank you for visiting our website. Please come again!</p>
           							<a href="#" class="feature-content-link blue-btn">See Details</a>
           						</div>
           					</div>
           					<div class="col-sm-4">
           						<div class="feature-content">
           							<img src={require("./../img/1-3.jpg")} alt="Image"/>
           							<h2 class="feature-content-title red-text">Parallax Layout</h2>
           							<p class="feature-content-description">Morbi sagittis justo a velit placerat ullamcorper quis quis velit. Sed convallis at risus ullamcorper auctor. Praesent quis velit neque. Quisque semper porta nisi vitae suscipit. Duis lectus magna, ornare ac scelerisque.
           							</p>
           							<a href="#" class="feature-content-link red-btn">Button Red</a>
           						</div>
           					</div>
           				</div>
           			</div>
           		</section>
           		</div>
        )
    }
}

export default About