import React, { Component } from 'react';

class Social extends Component {
    render() {
        return (
            <div class="social">
                {/*<a href="https://codepen.io/naafi" target="_blank"><i class="fab fa-codepen"></i></a>*/}
                {/*<a href="https://github.com/naaficodes" target="_blank"><i class="fab fa-github"></i></a>*/}
                {/*<a href="https://Instagram.com/iam_naafi" target="_blank"><i class="fab fa-instagram"></i></a>*/}
                {/*<a href="https://www.facebook.com/abdul.w.naafi" target="_blank"><i class="fab fa-facebook-f"></i></a>*/}
                {/*<a href="https://Linkedin.com/in/naafi" target="_blank"><i class="fab fa-linkedin-in"></i></a>*/}
                {/*<a href="https://medium.com/@iam_naafi" target="_blank"><i class="fab fa-medium-m"></i></a>*/}
            </div>
        )
    }
}

export default Social