import React, {Component} from 'react';
import {Button } from "react-bootstrap";


class Navbar extends Component {
    render() {
        return (
           	<nav class="navbar navbar-fixed-top">
           		<div class="container">
           			<div class="navbar-header">
           				<button type="button" class="navbar-toggle">
           					<span class="icon-bar"></span>
           					<span class="icon-bar"></span>
           					<span class="icon-bar"></span>
           				</button>
           			</div>
           			<div class="single-page-nav sticky-wrapper" id="tmNavbar">
           				<ul class="nav navbar-nav">
           					<li><a href="/">Homepage</a></li>
           					<li><a href="#Tick">Ticks</a></li>

           				</ul>
           			</div>
           		</div>
           	</nav>
        )
    }
}

export default Navbar