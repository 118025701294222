import  React from 'react';
const Footer= () => (
	<footer id="footer-area">
						<div class="container">
							<div class="row text-center">
								<div class="col-sm-12">
									<div class="footer-content">
										<p>"Conquer yourself and the world will follow"</p>
										</div>
									</div>
								</div>
							</div>

							<div class="container">
								<div class="row">
									<div class="col-sm-12 text-center">
										<p class="copy">"Copyright © 2020 Fresh Air Movement LLC"</p>
									</div>
								</div>
							</div>
						</footer>
        )


export default Footer
        