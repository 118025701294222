
import React, { Component } from 'react';


class Blog extends Component {
    render() {
        return (
        <div id="blog-area">
        <section id="blog-area">
            <div class="container">
                <div class="row text-center inner">
                    <div class="col-sm-6">
                        <div class="blog-content">
                            <img src={require("./../img/2-1.jpg")} alt="Image"/>
                            <h2>Two Column Left Side</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tempor eros eget eros maximus, ut cursus sem euismod. Donec iaculis tristique odio at consectetur. Nullam dignissim varius suscipit. Sed in leo sit amet velit finibus pretium.
                                Vestibulum vel mauris at erat mattis accumsan et ac lorem. Cras non venenatis orci, sed tincidunt massa. Duis nisl lectus, auctor eu sodales at, dignissim eu orci. Sed vitae venenatis magna, in blandit metus.</p>

                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="blog-content">
                                <img src={require("./../img/2-2.jpg")} alt="Image"/>
                                <h2>Two Column Right Side</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tempor eros eget eros maximus, ut cursus sem euismod. Donec iaculis tristique odio at consectetur. Nullam dignissim varius suscipit. Sed in leo sit amet velit finibus pretium.
                                Vestibulum vel mauris at erat mattis accumsan et ac lorem. Cras non venenatis orci, sed tincidunt massa. Duis nisl lectus, auctor eu sodales at, dignissim eu orci. Sed vitae venenatis magna, in blandit metus.</p>
                                    <span><a href="#">read more</a></span>
                                    <p id="blofr"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Blog